<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Настройка: Банера</strong>
    </div>

    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 v-if="!isPreview" class="mb-0">Изменение банеров</h5>
          <h5 v-else class="mb-0">Просмотр банеров</h5>
        </div>
      </div>

      <div class="card-body" v-if="!isPreview">
        <div class="banners_edit_list" v-for="(banner, i) in banners" :key="banner.id">
          <a-form-model :model="banner">
            <a-form-model-item label="Код" required>
              <a-input :value="banner.text" @input="onBannerTextInput($event, i)" />
            </a-form-model-item>

            <a-form-model-item label="Статус" required>
              <a-select v-model="banner.active" :disabled="!banner.text" placeholder="выберите статус">
                <a-select-option v-for="status in statuses" :key="status.id" :value="status.id">
                  {{ status.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item label="Форма">
              <a-input :value="banner.vertical ? 'Вертикальный' : banner.horizontal ? 'Горизонтальный' : banner.square ? 'Квадратный' : ''" disabled />
            </a-form-model-item>

            <a-form-model-item label="Дата начала" >
              <a-date-picker :value="banner.date_from" @change="onChangeDate($event, banner, dateTypes.from)" />
            </a-form-model-item>

            <a-form-model-item label="Дата окончания">
              <a-date-picker :value="banner.date_to"  @change="onChangeDate($event, banner, dateTypes.to)" />
            </a-form-model-item>
          </a-form-model>
        </div>
        <a-button class="ml-auto d-block" type="primary" @click="submit" :disabled="isDisabled">
          Сохранить изменения
        </a-button>
      </div>
      <div v-else class="card-body">
        <div v-for="banner in banners" :key="banner.id" class="banners_preview">
          <div v-if="banner.horizontal" v-html="banner.text" class="horizontal"></div>
          <div v-if="banner.vertical" v-html="banner.text" class="vertical"></div>
          <div v-if="banner.square" v-html="banner.text" class="square"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import users from '@/mixins/users'

export default {
  name: 'OverviewBanner',

  mixins: [users],

  data() {
    return {
      dateTypes: {
        from: 'date_from',
        to: 'date_to',
      },
      banners: [],
      statuses: [
        { name: 'Активен', id: 1 },
        { name: 'Не активен', id: 0 },
      ],
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
    }
  },

  created() {
    this.getBannersInfo()
  },

  computed: {
    isDisabled() {
      let isDisabled = false

      for (const index in this.banners) {
        for (const prop in this.banners[index]) {
          if (typeof this.banners[index][prop] !== 'object' && prop !== 'text' && !`${this.banners[index][prop]}`) {
            isDisabled = true
          } else if (typeof this.banners[index][prop] === 'object' && this.banners[index][prop] && !this.banners[index][prop].length) {
            isDisabled = true
          }
        }
      }

      return isDisabled
    },
  },

  methods: {
    onBannerTextInput({ target: input }, bannerIndex) {
      const currentBanner = this.banners[bannerIndex]
      currentBanner.text = input.value

      if (!input.value) {
        currentBanner.active = 0
      }
    },

    async getBannersInfo() {
      try {
        this.banners = (await this.$services.get(`admin/banners/${this.$route.params.id}`)).data.data.banners
      } catch (e) {
        console.log(e)
      }
    },

    async submit() {
      try {
        await this.$services.put(`admin/banners/${this.banners[0].banner_page_id}`, { banners: this.banners })
      } catch (e) {
        console.log(e)
      }
    },

    onChangeDate(date, banner, dateType) {
      banner[dateType] = date.format('YYYY-MM-DD HH:mm:SS')
    },
  },
}
</script>

<style scoped lang="scss">
.ant-form {
  border: 1px solid rgba(0, 0, 0, .3);
  border-radius: 5px;
  padding: 16px;
  margin-bottom: 24px;
  ::v-deep .ant-form-item-label {
    height: fit-content;
    line-height: 120%;
    label {
      height: fit-content;
    }
  }
  .ant-input-disabled, .ant-select-disabled {
    background-color: #ffffff;
    color: #595c97;
    ::v-deep .ant-select-selection {
      background-color: #ffffff;
    }
  }
  ::v-deep .ant-form-item-control {
    line-height: 30px;
  }
}
.banners_preview {
  div {
    margin: 24px auto;
  }
  .vertical {
    height: 600px;
    width: 160px;
  }
  .horizontal {
    height: 90px;
    width: 728px;
  }
  .square {
    height: 280px;
    width: 336px;
  }
  ::v-deep img {
    width: 100%;
    height: 100%;
  }
}
</style>
